<template>
    <div class="login container ">
        <div class="header_gruop page_width flex_sb">
        <div class="icon_home flex_center" @click="toBack()"><svg t="1729563443244" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6080" id="mx_n_1729563443245" width="16" height="16"><path d="M398.912 513.152l377.28-384.256c27.904-28.416 27.904-74.432 0-102.784-27.84-28.352-73.024-28.352-100.8 0L247.808 461.504C233.792 475.776 226.88 494.464 226.944 513.152 226.88 531.84 233.792 550.464 247.808 564.736l427.584 435.392c27.84 28.352 73.024 28.352 100.8 0 27.904-28.352 27.904-74.368 0-102.656L398.912 513.152z" fill="#16181c" p-id="6081"></path></svg></div>
        <div class="header_title">Change password</div>
        <div></div>
      </div>
      <div class="scoller grid_content" :v-infinite-scroll="false" style="overflow:auto;-webkit-overflow-scrolling: touch">
          <div class="page_info has_header">
              <div class="page_container">
                  <div class="page_info_title">Change password</div>
                  <div class="page_info_msg">Please enter your email address registered with Bigplayers.</div>
                  <div class="input_group">
                      <div class="input_label">E-mail*</div>
                      <div class="input_value">
                          <el-input v-model="userInfo.email"  size="medium" disabled></el-input>
                      </div>
                  </div>
                  <div class="input_group">
                    <div class="input_label">Password*</div>
                    <div class="input_value flex">
                        <el-input v-model="oldPassword" :type="openEye?'text':'password'" size="medium"></el-input>
                        <div class="eye flex_center" @click="openEye=!openEye">
                            <div class="close_eye eye_icon flex_center"  v-show="!openEye">
                                <svg t="1729598175701" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7917" id="mx_n_1729598175702" width="18" height="18"><path d="M863.126069 354.551172c-6.285241-8.651034-19.632552-9.780966-29.837241-1.871448-3.531034 2.754207-5.049379 4.378483-8.474483 8.15669l-0.247173 0.388414c-56.637793 69.949793-336.825379 347.524414-628.347586 0.388413-0.105931-0.141241-0.105931-0.282483-0.247172-0.388413-0.353103-0.635586-0.847448-1.129931-1.271173-1.659587-7.909517-10.169379-21.46869-12.817655-30.190344-6.038069-8.403862 6.673655-9.145379 19.879724-1.871449 29.978483v0.105931c0.247172 0.247172 0.247172 0.388414 0.459035 0.635586v0.141242a796.742621 796.742621 0 0 0 100.069517 95.655724l-78.000552 63.699862a17.337379 17.337379 0 0 0 21.857104 26.941793l83.226482-67.831172c0.635586-0.529655 1.094621-1.024 1.624276-1.518345 40.783448 28.354207 79.942621 47.457103 117.477518 59.286069l-23.693242 86.369103a17.549241 17.549241 0 0 0 12.21738 21.398069 17.513931 17.513931 0 0 0 21.398068-12.217379l23.799173-86.828138a315.603862 315.603862 0 0 0 158.190345-8.15669c0.141241 0.105931 0.141241 0.353103 0.141241 0.494345l44.844138 104.483311a17.37269 17.37269 0 0 0 31.955862-13.629794l-44.208552-103.070896c40.712828-16.772414 77.188414-39.936 109.285518-64.229517a20.833103 20.833103 0 0 0 2.648275 3.778207l59.674483 62.817103a17.408 17.408 0 0 0 24.50538 0.635586 17.44331 17.44331 0 0 0 0.635586-24.540689l-59.639173-62.817104-1.129931-1.129931c37.888-31.70869 67.937103-62.923034 89.476414-83.085241a18.820414 18.820414 0 0 0 2.507035-3.142621 15.183448 15.183448 0 0 0 1.165241-1.765517c4.201931-7.344552 4.413793-15.536552 0-21.43338z" fill="#161619" p-id="7918"></path></svg>
                            </div>
                            <div class="open_eye eye_icon flex_center" v-show="openEye">
                                <svg t="1729598249518" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8483" id="mx_n_1729598249519" width="18" height="18"><path d="M512 670.72c-84.992 0-154.624-71.68-154.624-158.72 0-88.064 69.632-158.72 154.624-158.72s154.624 71.68 154.624 158.72c0 88.064-69.632 158.72-154.624 158.72z m0-286.72c-68.608 0-123.904 57.344-123.904 128S443.392 640 512 640c68.608 0 123.904-57.344 123.904-128S580.608 384 512 384z" fill="#161619" p-id="8484"></path><path d="M512 586.752c-39.936 0-72.704-33.792-72.704-74.752s32.768-74.752 72.704-74.752c39.936 0 72.704 33.792 72.704 74.752s-32.768 74.752-72.704 74.752z m0-118.784c-23.552 0-41.984 19.456-41.984 44.032s18.432 44.032 41.984 44.032 41.984-19.456 41.984-44.032-18.432-44.032-41.984-44.032z" fill="#161619" p-id="8485"></path><path d="M512 764.928c-200.704 0-364.544-113.664-364.544-252.928s163.84-252.928 364.544-252.928S876.544 372.736 876.544 512 712.704 764.928 512 764.928z m0-475.136C327.68 289.792 178.176 389.12 178.176 512S327.68 734.208 512 734.208 845.824 634.88 845.824 512C845.824 390.144 696.32 289.792 512 289.792z" fill="#161619" p-id="8486"></path></svg>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div class="input_group">
                    <div class="input_label">newPassword*</div>
                    <div class="input_value flex">
                        <el-input v-model="newPassword" :type="openEye_new?'text':'password'" size="medium"></el-input>
                        <div class="eye flex_center" @click="openEye_new=!openEye_new">
                            <div class="close_eye eye_icon flex_center"  v-show="!openEye_new">
                                <svg t="1729598175701" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7917" id="mx_n_1729598175702" width="18" height="18"><path d="M863.126069 354.551172c-6.285241-8.651034-19.632552-9.780966-29.837241-1.871448-3.531034 2.754207-5.049379 4.378483-8.474483 8.15669l-0.247173 0.388414c-56.637793 69.949793-336.825379 347.524414-628.347586 0.388413-0.105931-0.141241-0.105931-0.282483-0.247172-0.388413-0.353103-0.635586-0.847448-1.129931-1.271173-1.659587-7.909517-10.169379-21.46869-12.817655-30.190344-6.038069-8.403862 6.673655-9.145379 19.879724-1.871449 29.978483v0.105931c0.247172 0.247172 0.247172 0.388414 0.459035 0.635586v0.141242a796.742621 796.742621 0 0 0 100.069517 95.655724l-78.000552 63.699862a17.337379 17.337379 0 0 0 21.857104 26.941793l83.226482-67.831172c0.635586-0.529655 1.094621-1.024 1.624276-1.518345 40.783448 28.354207 79.942621 47.457103 117.477518 59.286069l-23.693242 86.369103a17.549241 17.549241 0 0 0 12.21738 21.398069 17.513931 17.513931 0 0 0 21.398068-12.217379l23.799173-86.828138a315.603862 315.603862 0 0 0 158.190345-8.15669c0.141241 0.105931 0.141241 0.353103 0.141241 0.494345l44.844138 104.483311a17.37269 17.37269 0 0 0 31.955862-13.629794l-44.208552-103.070896c40.712828-16.772414 77.188414-39.936 109.285518-64.229517a20.833103 20.833103 0 0 0 2.648275 3.778207l59.674483 62.817103a17.408 17.408 0 0 0 24.50538 0.635586 17.44331 17.44331 0 0 0 0.635586-24.540689l-59.639173-62.817104-1.129931-1.129931c37.888-31.70869 67.937103-62.923034 89.476414-83.085241a18.820414 18.820414 0 0 0 2.507035-3.142621 15.183448 15.183448 0 0 0 1.165241-1.765517c4.201931-7.344552 4.413793-15.536552 0-21.43338z" fill="#161619" p-id="7918"></path></svg>
                            </div>
                            <div class="open_eye eye_icon flex_center" v-show="openEye_new">
                                <svg t="1729598249518" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8483" id="mx_n_1729598249519" width="18" height="18"><path d="M512 670.72c-84.992 0-154.624-71.68-154.624-158.72 0-88.064 69.632-158.72 154.624-158.72s154.624 71.68 154.624 158.72c0 88.064-69.632 158.72-154.624 158.72z m0-286.72c-68.608 0-123.904 57.344-123.904 128S443.392 640 512 640c68.608 0 123.904-57.344 123.904-128S580.608 384 512 384z" fill="#161619" p-id="8484"></path><path d="M512 586.752c-39.936 0-72.704-33.792-72.704-74.752s32.768-74.752 72.704-74.752c39.936 0 72.704 33.792 72.704 74.752s-32.768 74.752-72.704 74.752z m0-118.784c-23.552 0-41.984 19.456-41.984 44.032s18.432 44.032 41.984 44.032 41.984-19.456 41.984-44.032-18.432-44.032-41.984-44.032z" fill="#161619" p-id="8485"></path><path d="M512 764.928c-200.704 0-364.544-113.664-364.544-252.928s163.84-252.928 364.544-252.928S876.544 372.736 876.544 512 712.704 764.928 512 764.928z m0-475.136C327.68 289.792 178.176 389.12 178.176 512S327.68 734.208 512 734.208 845.824 634.88 845.824 512C845.824 390.144 696.32 289.792 512 289.792z" fill="#161619" p-id="8486"></path></svg>
                            </div>
                        </div>
                    </div>
                  </div>
              
                  <div class="login_btn_group flex_col_center">
                      <div class="btn_login def_btn flex_center" @click="changePwd()">confirm</div> 
                  </div>
              </div>
              
          </div>
      </div>
    </div>
  </template>
  
  <script>
  import cookies from '../../utils/cookie';
  export default {
      components:{
        
      },
      data(){
          return{
              email:'7382433@qq.com',
              oldPassword:'',
              newPassword:'',
              openEye_new:false,
              openEye:false,
              userInfo:{}
          }
      },
      created(){
        this.userInfo = cookies.getUserInfo()
      },
      methods:{
        toBack(){
            this.$router.back()
        },
        changePwd(){
            let oldPassword = this.oldPassword
            let newPassword = this.newPassword
            // if(oldPassword != newPassword){
            //     this.$message.error('Two passwords are inconsistent')
            //     return
            // }
            if(!oldPassword || !newPassword){
                this.$message.error('Missing required fields')
                return
            }
            let _this = this
            this.$axiosApi.updatePwd({oldPwd:oldPassword,newPwd:newPassword,}).then(res=>{
                if(res.code == 200){
                    this.$message.success(res.msg)
                    cookies.removeToken()
                    cookies.removeUserInfo()
                    setTimeout(()=>{
                        _this.$router.push('/login')
                     
                    },1500)
                    
                }else{
                    this.$message.error(res.msg)
                }
            })
        }
      }
  }
  </script>
  
  <style lang="scss" scoped>
  /deep/.el-input__inner{
      border: none !important;;
      padding: 0 !important;
      background-color: #fff!important;
      font-size: 14px;
  }
  .page_container{
    padding-top:0;
    .page_info_title{
        font-weight: bold;
        font-size: 30px;
    }
    .page_info_msg{
        margin:34px 0;
    }
  }
  
  </style>